import classNames from 'classnames';
import React from 'react';
import { UniversityCourseWithoutLessons } from '../../types';
import styles from './UniversityCourseHeader.module.scss';

interface UniversityCourseHeaderProps {
  course: UniversityCourseWithoutLessons;
  showMobileDescription?: boolean;
  upcoming?: boolean;
}

const UniversityCourseHeader: React.SFC<UniversityCourseHeaderProps> = ({
  course,
  showMobileDescription,
  upcoming,
}) => (
  <>
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={course.icon.publicURL} alt="" role="presentation" />
        <div
          className={classNames(styles.iconText, {
            [styles.invert]: course.invertIconText,
          })}
        >
          {course.num < 10 && '0'}
          {course.num}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.number}>Course {course.num}</div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{course.name}</div>
          {upcoming && <div className={styles.upcoming}>Coming Soon!</div>}
        </div>
        <div className={styles.description}>{course.description}</div>
      </div>
    </div>
    {showMobileDescription && (
      <div className={styles.mobileDescription}>{course.description}</div>
    )}
  </>
);

export default UniversityCourseHeader;
