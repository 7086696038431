import React from 'react';
import UntitledButton from '../ui/UntitledButton';
import Layout from './Layout';
import MarketingFooter from './MarketingFooter';
import UntitledNavbar from './UntitledNavbar';
import UntitledStyle from './UntitledStyle';

interface UniversityLayoutProps {
  children: React.ReactNode;
}

const UniversityLayout: React.SFC<UniversityLayoutProps> = ({ children }) => (
  <Layout>
    <UntitledStyle>
      <UntitledNavbar name="university" to="/university/collaborators/">
        <UntitledButton
          block
          variant="secondary"
          to="https://help.slab.com"
          target="_blank"
        >
          Help Center
        </UntitledButton>
      </UntitledNavbar>
      {children}
    </UntitledStyle>
    <MarketingFooter />
  </Layout>
);

export default UniversityLayout;
