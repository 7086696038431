import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import wrapTrack from '../../utils/wrapTrack';
import styles from './UntitledButton.module.scss';

export interface UntitledButtonProps {
  block?: boolean;
  variant?: 'primary' | 'secondary';
  to?: string;
  target?: string;
  large?: boolean;
  track?: { label: string; library: string };
  onClick?: React.MouseEventHandler;
}

const UntitledButton: React.FC<
  UntitledButtonProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>
> = ({
  block,
  variant,
  to,
  children,
  target,
  large,
  onClick: originalOnClick,
  track,
  ...props
}) => {
  const classes = classNames(styles.button, variant && styles[variant], {
    [styles.block]: block,
    [styles.large]: large,
  });

  const onClick = wrapTrack(track, originalOnClick);

  if (to) {
    return to.match(/^(https?|mailto):/) || to.match(/^#/) ? (
      <a className={classes} href={to} target={target} onClick={onClick}>
        {children}
      </a>
    ) : (
      <Link className={classes} to={to} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" className={classes} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default UntitledButton;
