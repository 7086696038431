const secondsToTimestamp = (
  seconds: number,
  { stripLeadingZero = false } = {},
) => {
  const timestamp = new Date(seconds * 1000).toISOString().substr(14, 5);

  return stripLeadingZero && timestamp[0] === '0'
    ? timestamp.substr(1)
    : timestamp;
};

export default secondsToTimestamp;
