import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import playIcon from '../../images/university/play.svg';
import { UniversityCourse } from '../../types';
import secondsToTimestamp from '../../utils/secondsToTimestamp';
import styles from './UniversityCourseFull.module.scss';
import UniversityCourseHeader from './UniversityCourseHeader';

interface UniversityCourseFullProps {
  course: UniversityCourse;
  activeNum?: number;
  showMobileDescription?: boolean;
}

const UniversityCourseFull: React.SFC<UniversityCourseFullProps> = ({
  course,
  activeNum,
  showMobileDescription,
}) => (
  <>
    <UniversityCourseHeader
      course={course}
      showMobileDescription={showMobileDescription}
    />
    {showMobileDescription && <div className={styles.separator} />}
    <div className={styles.lessons}>
      {course.lessons.map((lesson) => (
        <Link
          to={lesson.url}
          key={lesson.num}
          className={classNames(styles.lesson, {
            [styles.active]: lesson.num === activeNum,
          })}
        >
          <div className={styles.lessonInner}>
            <img src={playIcon} alt="" role="presentation" />
            <div className={styles.lessonName}>
              {activeNum && lesson.num === activeNum + 1 && (
                <strong>Next: </strong>
              )}
              {lesson.name}
            </div>
            <div className={styles.lessonDuration}>
              {secondsToTimestamp(lesson.duration)}
            </div>
          </div>
        </Link>
      ))}
    </div>
  </>
);

export default UniversityCourseFull;
