import { graphql } from 'gatsby';
import React from 'react';
import Meta from '../components/layout/Meta';
import UniversityLayout from '../components/layout/UniversityLayout';
import UniversityCourseFull from '../components/university/UniversityCourseFull';
import UniversityCourseHeader from '../components/university/UniversityCourseHeader';
import { UniversityRolesYaml } from '../graphql-types';
import universityImage from '../images/university/university.svg';
import { toUniversityRole } from '../utils/toUniversityType';
import styles from './UniversityRoleTemplate.module.scss';

interface UniversityRoleTemplateProps {
  data: {
    role: UniversityRolesYaml;
  };
}

const UniversityRoleTemplate: React.SFC<UniversityRoleTemplateProps> = ({
  data,
}) => {
  const role = toUniversityRole(data.role);

  return (
    <UniversityLayout>
      <Meta title={`${role.name} - Slab University`} />
      <div className={styles.header}>
        <div>
          <h1 className={styles.title}>Slab University</h1>
          <h2 className={styles.subtitle}>
            Interactive how-tos, best practices, and resources – designed for
            your role
          </h2>
        </div>
        <img src={universityImage} alt="" role="presentation" />
      </div>
      <div className={styles.separator} />
      <div className={styles.courses}>
        {role.courses.map((course) => (
          <div className={styles.course} key={course.num}>
            <UniversityCourseFull course={course} showMobileDescription />
          </div>
        ))}
      </div>
      {role.upcoming.length ? (
        <div className={styles.upcoming}>
          <div className={styles.upcomingHeader}>Coming soon</div>
          {role.upcoming.map((course) => (
            <div className={styles.upcomingCourse} key={course.num}>
              <UniversityCourseHeader
                course={course}
                showMobileDescription
                upcoming
              />
            </div>
          ))}
        </div>
      ) : null}
    </UniversityLayout>
  );
};

export const query = graphql`
  query UniversityRolePageQuery($slug: String!) {
    role: universityRolesYaml(fields: { slug: { eq: $slug } }) {
      name
      courses {
        name
        description
        invertIconText
        num
        lessons {
          num
          fields {
            name
            duration
            roleSlug
            slug
          }
        }
        fields {
          icon {
            publicURL
          }
        }
      }
      upcoming {
        name
        description
        invertIconText
        num
        fields {
          icon {
            publicURL
          }
        }
      }
    }
  }
`;

export default UniversityRoleTemplate;
