import {
  UniversityCoursesYaml,
  UniversityLessonsYaml,
  UniversityRolesYaml,
} from '../graphql-types';
import { UniversityLesson, UniversityRole } from '../types';

const toUniversityCourseWithoutLessons = (node: UniversityCoursesYaml) => ({
  name: node.name,
  description: node.description,
  icon: {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    publicURL: node.fields!.icon!.publicURL,
  },
  invertIconText: !!node.invertIconText,
  num: node.num,
});

const toUniversityCourse = (node: UniversityCoursesYaml) => ({
  ...toUniversityCourseWithoutLessons(node),
  // TODO: Fix this the next time the file is edited.
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  lessons: node.lessons!.map((lesson) => ({
    name: lesson!.fields!.name,
    duration: lesson!.fields!.duration,
    num: lesson!.num,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/university/${lesson!.fields!.roleSlug}/${node.num}/lessons/${
      lesson!.num
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    }-${lesson!.fields!.slug}/`,
  })),
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
});

export const toUniversityLesson = (node: UniversityLessonsYaml) =>
  ({
    // TODO: Fix this the next time the file is edited.
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    name: node.fields!.name,
    description: node.fields!.description,
    vimeoId: node.vimeoId,
    resources: node.resources,
    num: node.num,
    aspectRatio: node.fields!.aspectRatio,
    course: {
      ...toUniversityCourse(node.course!),
      background: {
        publicURL: node.course!.fields!.background!.publicURL,
      },
    },
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  } as UniversityLesson);

export const toUniversityRole = (node: UniversityRolesYaml) =>
  ({
    name: node.name,
    // TODO: Fix this the next time the file is edited.
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    courses: node.courses!.map((course) => toUniversityCourse(course!)),
    upcoming: node.upcoming!.map(
      (upcoming) => toUniversityCourseWithoutLessons(upcoming!),
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    ),
  } as UniversityRole);
